import React, { Component } from 'react'
import './App.css'
import {
  Route,
  withRouter,
} from "react-router-dom";

import Confirmation from "./login/Confirmation"
import PwResetPage from "./login/PwResetPage"
import "./transitions.css"

class Spa extends Component {
  render() {
    return (
      <div className="App">
        <Route exact path="/" component={() => { 
          window.location.href = 'https://athloslive.com'; 
          return null;
        }}/>
        <Route path="/confirmation" component={Confirmation} />
        <Route path="/pwResetPage" component={PwResetPage} /> 
      </div>
    )
  }
}

export default withRouter(Spa)
